import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './style.css';

export default function Partners() {
	return (
		<div className="landing-partners" id='partners'>
			<h2 className="landing-partners__header">Партнёры</h2>
			<Swiper
				slidesPerView={"auto"}
				allowTouchMove={true}
				grabCursor={true}
				breakpoints={{}}
			>
				<SwiperSlide>
					<div className="landing-partners__partner">
						<svg className="landing-partners__partner-1">
							<image href="/img/partner-1.svg" />
						</svg>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="landing-partners__partner">
						<svg className="landing-partners__partner-2">
							<image href="/img/partner-2.svg" />
						</svg>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="landing-partners__partner">
						<svg className="landing-partners__partner-3">
							<image href="/img/partner-3.svg" />
						</svg>
						<div className="landing-partners__text">Правительство<br />Тюменской области</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="landing-partners__partner">
						<svg className="landing-partners__partner-4">
							<image href="/img/АГАМА.png" x="25%" y="25%" width="50%" height="50%" />
						</svg>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="landing-partners__partner">
						<svg className="landing-partners__partner-5">
							<image href="/img/Cygenic.svg" x="15%" y="15%" width="70%" height="70%" />
						</svg>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="landing-partners__partner">
						<svg className="landing-partners__partner-6">
							<image href="/img/Titan.jpg" x="15%" y="15%" width="70%" height="70%" />
						</svg>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	)
}
